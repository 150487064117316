import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "index"*/ '../pages/MyPoisView.vue'),
    meta: {
      auth: true,
      title: 'My POIs',
    }
  },
  {
    path: '/poi/:id',
    name: 'poi',
    component: () => import(/* webpackChunkName: "index"*/ '../pages/MyPoiView.vue'),
    meta: {
      auth: true,
      title: 'POI Info Page',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth"*/ '../pages/auth/LoginView.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "auth"*/ '../pages/auth/LogoutView.vue'),
    meta: {
      title: 'Logout',
    }
  },
  {
    path: '/confirm-terms',
    name: 'confirm-terms',
    component: () => import(/* webpackChunkName: "auth"*/ '../pages/auth/ConfirmTermsView.vue'),
    meta: {
      auth: true,
      title: 'Confirm terms',
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "auth"*/ '../pages/auth/Profile.vue'),
    meta: {
      auth: true,
      title: 'My profile',
    }
  },
  {
    path: '/profile-edit',
    name: 'profile-edit',
    component: () => import(/* webpackChunkName: "auth"*/ '../pages/auth/ProfileEdit.vue'),
    meta: {
      auth: true,
      title: 'Edit profile',
    }
  },
  {
    path: '/403',
    name: '403',
    component: () => import(/* webpackChunkName: "index"*/ '../pages/errors/403Page.vue'),
    meta: {
      title: '403',
    }
  },
  {
    path: '/*',
    component: () => import(/* webpackChunkName: "index"*/ '../pages/errors/404Page.vue'),
    meta: {
      title: '404',
    }
  },
]

// propagate metadata recursively to child routes
function propagateMetadata(routes, meta) {
  routes.forEach(route => {
    if (route.meta === undefined) {
      route.meta = meta
    } else {
      route.meta = Object.assign({}, meta, route.meta);
    }

    if (route.children !== undefined) {
      propagateMetadata(route.children, route.meta)
    }
  })
}
propagateMetadata(routes, {})

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_DEPLOYED_SITE_NAME}`

  // logout
  if (to.name === 'logout') {
    next()
    return
  }

  // check user confirm terms
  if (to.name !== 'confirm-terms' && store.state.auth.authenticated && !store.state.auth.user.confirm_terms) {
    next({name: 'confirm-terms'})
    return
  }
  // check user need edit profile
  if (to.name !== 'profile-edit' && to.name !== 'confirm-terms' && store.state.auth.authenticated && !store.state.auth.user.first_name) {
    next({name: 'profile-edit'})
    return
  }

  // check permissions
  if (to.meta.auth && !store.state.auth.authenticated) {
    next({name: 'login'})
    return
  }

  next()
})

export default router
