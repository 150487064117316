<template>
  <div class="amb-img" :style="'border-color: ' + borderColor + '; width: ' + size + 'px; height: ' + size + 'px;'">
    <img v-if="currentAvatarImage && !onlyEmpty" :src="currentAvatarImage" alt="" />
    <div v-else class="amb-placeholder" :style="{ fontSize: size/2 + 'px' }">{{ userAvatarText }}</div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AvatarIcon",
  props: {
    avatarImage: {
      type: String,
      default: ''
    },
    onlyEmpty: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 50
    },
    borderColor: {
      type: String,
      default: '#1976d2'
    },
  },
  computed: {
    ...mapGetters({
      userAvatar: 'auth/userAvatar',
      userAvatarText: 'auth/userAvatarText',
    }),
    currentAvatarImage: function() {
      return this.avatarImage || this.userAvatar
    }
  },
};
</script>

<style scoped>
.amb-img {
  border: 2px solid #1976d2;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.amb-img img {
  left: 50%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.amb-img:before {
  border: 2px solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.amb-img-com {
  border-color: #f2f4f8;
}
.amb-placeholder {
  align-items: center;
  background: #f2f4f8;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  text-transform: uppercase;
}
</style>
