import axios from 'axios';

export default class RequestHelpers {
  store = null
  router = null

  constructor(options) {
    this.store = options.store
    this.router = options.router
  }

  get(url, config) {
    return this.request('get', url, {}, config)
  }

  post(url, data, config) {
    return this.request('post', url, data, config)
  }

  put(url, data, config) {
    return this.request('put', url, data, config)
  }

  patch(url, data, config) {
    return this.request('patch', url, data, config);
  }

  delete(url, data, config) {
    return this.request('delete', url, data, config)
  }

  request(method, url, data, config) {
    this.store.dispatch('overlay/showOverlay', true)

    let defaultConfig = {
      method: method,
      url: url,
      data: data,
      headers: {
        'AMB-TOKEN': this.store.getters['auth/userToken'],
      },
    }
    config = this.mergeObjects(config, defaultConfig)

    return new Promise((resolve, reject) => {
      axios(config).then(({data}) => {
        if (data instanceof Object && 'success' in data) {
          if (data.success) {
            resolve(data.result)
          } else {
            this.store.dispatch('snackbar/showSnack', {
              text: (data.message ? data.message : 'Error'),
              color: "error",
            })
            reject(data)
          }
        } else {
          resolve(data)
        }
      }).catch(({response}) => {
        switch(response.status) {
          case 401:
            this.store.dispatch('snackbar/showSnack', {
              text: (response.data.message ? response.data.message : 'Error'),
              color: "error",
            })
            this.store.dispatch('auth/logout')
            this.router.push({name: 'login'})
            break;
          case 403:
            this.router.push({name: '403'})
            break;
          default:
            this.defaultCatch(response.data)
            reject(response)
        }
      }).finally(() => {
        this.defaultFinally()
      })
    });
  }

  defaultCatch(data) {
    this.store.dispatch('snackbar/showSnack', {
      text: (data.message ? data.message : 'Error'),
      color: 'error',
    })
  }

  defaultFinally() {
    this.store.dispatch('overlay/showOverlay', false)
  }

  mergeObjects(target, source) {
    if (target !== undefined) {
      for (const key of Object.keys(source || {})) {
        if (source[key] instanceof Object) {
          Object.assign(source[key], this.mergeObjects(target[key], source[key]))
        }
      }
    }

    return Object.assign({}, target || {}, source);
  }
}