import localForage from 'localforage';

export default class VideoStorage {
  store = null
  localStorage = null

  constructor(options) {
    this.store = options.store

    this.localStorage = localForage.createInstance({
      name: "videoStorage"
    });
  }

  async setVideo(key, value, callback) {
    await this.localStorage.setItem(key, value, (err, value) => {
      if (err) {
        this.store.dispatch('snackbar/showSnack', {
          text: (err ? err : 'Error set video to local Storage'),
          color: "error",
        })
      } else {
        if (typeof callback === 'function') {
          callback(value)
        }
      }
    })
  }

  async getVideo(key, callback) {
    await this.localStorage.getItem(key, (err, value) => {
      if (err) {
        this.store.dispatch('snackbar/showSnack', {
          text: (err ? err : 'Error get video from local Storage'),
          color: "error",
        })
      } else {
        if (typeof callback === 'function') {
          callback(value)
        }
      }
    })
  }

  async removeItem(key, callback) {
    await this.localStorage.removeItem(key, (err, value) => {
      if (err) {
        this.store.dispatch('snackbar/showSnack', {
          text: (err ? err : 'Error remove video from local Storage'),
          color: "error",
        })
      } else {
        if (typeof callback === 'function') {
          callback(value)
        }
      }
    })
  }

  async getVideosByPrefix(prefix, callback) {
    let data = {}

    await this.localStorage.keys().then(async (keys) => {
      for (const key of keys) {
        if (key.indexOf(prefix) === 0) {
          await this.getVideo(key, (value) => {
            data[key.slice(prefix.length)] = value
          })
        }
      }

      if (typeof callback === 'function') {
        callback(data)
      }
    }).catch((err) => {
      this.store.dispatch('snackbar/showSnack', {
        text: (err ? err : 'Error get videos in local Storage'),
        color: "error",
      })
    });
  }
}