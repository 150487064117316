
export default {
  POI_STATUSES: {
    NEW: 0,
    IN_REVIEW: 1,
    FOR_EDITOR: 2,
    DOWNLOADED: 3,
    UPLOADED: 4,
    DONE: 5,
  },
  VIDEO_STATUSES: {
    NEW: 0,
    DOWNLOADED: 1,
    IN_PROGRESS: 2,
    DONE: 3,
    ARCHIVE: 4,
    REJECTED: 5,
    ACCEPTED: 6,
    ERROR: 7,
  },
  PAYMENTS_STATUSES: {
    0: 'Not paid',
    1: 'Partial paid',
    2: 'Paid',
  },
  AMB_DISPLAY_RULE: {
    ALIAS: 1,
    INITIALS: 2,
    NICKNAME: 3,
  },
  AMB_DISPLAY_RULE_LABEL: {
    1: 'Alias',
    2: 'Initials',
    3: 'Display name',
  },
  TERMS_VERSION: '1.2',
}
