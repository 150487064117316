export default {
  namespaced: true,

  state: {
    text: '',
    color: '',
    timeout: '',
  },

  getters: {
    color(state) {
      return state.color;
    },

    text(state) {
      return state.text;
    },

    timeout(state) {
      return state.timeout;
    },
  },

  mutations: {
    SHOW_MESSAGE(state, value) {
        state.text = value.text;
        state.color = value.color;
        state.timeout = value.timeout;
    },
  },

  actions: {
    showSnack({ commit }, value) {
      commit('SHOW_MESSAGE', value);
    },
  },
};
