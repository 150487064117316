<template>
    <v-app flex>
      <v-app-bar app v-if="showBar()">
        <v-toolbar-title>
          <a
            class="mr-3"
            @click="$router.push({name: 'home'}).catch(() => {})"
            v-if="this.$route.name !== 'home' && this.$route.name !== 'confirm-terms' && !this.isFirstVisit"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </a>

          {{ this.$route.meta.title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <a
          class="mr-3 custom-badge"
          @click="$router.push({name: 'profile'}).catch(() => {})"
          style="line-height: 0;"
        >
          <v-badge
            bottom
            overlap
            offset-x="18"
            offset-y="18"
          >
            <template v-slot:badge>
              <v-icon size="15">mdi-pen</v-icon>
            </template>

            <avatar-icon/>
          </v-badge>
        </a>

        <v-btn
            icon
            @click="$router.push({name: 'logout'}).catch(() => {})"
            v-if="$store.state.auth.authenticated"
        >
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <router-view/>
      </v-main>

      <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">
        <div v-html="snackbar.text"></div>

        <template v-slot:action="{ attrs }">
          <v-icon
              v-bind="attrs"
              small
              @click="snackbar.show = false"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>

      <v-overlay :value="overlay" z-index="210">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AvatarIcon from "@/components/AvatarIcon.vue";
export default {
  name: "defaultLayout",
  components: {
    AvatarIcon
  },
  data: () => ({
    snackbar: {
      text: '',
      color: '',
      timeout: -1,
      show: false,
    },
    overlay: false,
  }),
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/SHOW_MESSAGE") {
        this.snackbar.text = state.snackbar.text;
        this.snackbar.color = state.snackbar.color;
        this.snackbar.timeout = state.snackbar.timeout;
        this.snackbar.show = true;
      }
      if (mutation.type === "overlay/SHOW_OVERLAY") {
        this.overlay = state.overlay.show;
      }
    });

    if (this.userName) {
      this.$requestHelpers.get('/api/ambassador/user')
        .then((data) => {
          this.updateInfo(data)
        })
    }
  },
  computed: {
    ...mapGetters({
      isFirstVisit: 'auth/isFirstVisit',
      userName: 'auth/userName',
      hasRole: 'auth/hasRole',
      hasPermissions: 'auth/hasPermissions',
    }),
  },
  methods: {
    ...mapActions({
      updateInfo: 'auth/login',
    }),

    showBar() {
      return !['login'].includes(this.$route.name);
    }
  },
};
</script>

<style>
html {
  overflow: initial;
}
.custom-badge .v-badge__badge{
  z-index: 4;
}
</style>
