export default {
  namespaced: true,

  state: {
    show: 0,
  },

  getters: {
    show(state) {
      return !!state.show;
    },
  },

  mutations: {
    SHOW_OVERLAY(state, value) {
      if (value) {
        state.show += 1
      } else {
        state.show -= 1
      }
    },
  },

  actions: {
    showOverlay({ commit }, value) {
      commit('SHOW_OVERLAY', value);
    },
  },
};
