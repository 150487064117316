import requestHelpers from "./RequestHelpers";
import videoStorage from "./VideoStorage";
import Constants from "./Constants";
import Websocket from "./Websocket";

export default {
  install: (Vue, options) => {
    const helpersRequest = new requestHelpers(options)
    Vue.prototype.$requestHelpers = helpersRequest
    Vue.$requestHelpers = helpersRequest

    const helpersVideo = new videoStorage(options)
    Vue.prototype.$videoStorage = helpersVideo
    Vue.$videoStorage = helpersVideo

    Vue.prototype.$constants = Constants
    Vue.$constants = Constants

    const websocket = new Websocket(options)
    Vue.prototype.$websocket = websocket
    Vue.$websocket = websocket
  }
}