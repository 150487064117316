import Echo from 'laravel-echo';

export default class Websocket {
  initEcho = false
  store = false

  constructor(options) {
    this.store = options.store

    window.io = require('socket.io-client')
  }

  init() {
    if (!this.initEcho) {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_SOCKET_HOST,
        auth: {
          headers: {
            'AMB-TOKEN': this.store.getters['auth/userToken'],
          },
        },
      });

      this.initEcho = true
    }
  }

  Echo() {
    this.init()

    return window.Echo;
  }
}