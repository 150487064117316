
export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: {},
    permissions: [],
    roles: [],
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },

    user(state) {
      return state.user;
    },

    userId(state) {
      return state.user?.id;
    },

    userName(state) {
      return state.user?.login;
    },

    userToken(state) {
      return state.user?.amb_token;
    },

    userAvatar(state) {
      return state.user?.avatar_link ? process.env.VUE_APP_PROXY + state.user.avatar_link : '';
    },

    userAvatarText(state) {
      let text = state.user?.nickname;
      if (!text) text = state.user?.first_name;
      if (!text) text = state.user?.login;
      if (text) {
        return Array.from(text)[0].toUpperCase();
      }
      return '';
    },

    isFirstVisit(state) {
      return !state.user?.first_name;
    },

    permissions(state) {
      return state.permissions;
    },

    hasPermissions: (state) => (permission) =>  {
      if (!permission) {
        return true
      }
      return state.permissions.some((value) => {return value === permission});
    },

    roles(state) {
      return state.roles;
    },

    hasRole: (state) => (role) =>  {
      if (!role) {
        return true
      }
      return state.roles.some((value) => {return value === role});
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_USER(state, value) {
      state.user = value;
    },

    SET_PERMISSIONS(state, value) {
      state.permissions = value;
    },

    SET_ROLES(state, value) {
      state.roles = value;
    },
  },

  actions: {
    login({ commit }, data) {
      commit('SET_AUTHENTICATED', true);
      commit('SET_USER', data);
      commit('SET_PERMISSIONS', data.permissions ? data.permissions : []);
      commit('SET_ROLES', data.roles ? data.roles : []);
    },

    logout({commit}) {
      commit('SET_AUTHENTICATED',false)
      commit('SET_USER',{})
      commit('SET_PERMISSIONS', []);
      commit('SET_ROLES', []);
    }
  },
};
