import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './auth';
import snackbar from './snackbar';
import overlay from './overlay';

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ['auth']
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    snackbar,
    overlay,
  },
  plugins: [dataState],
});
