import Vue from 'vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import VuetifyConfirm from 'vuetify-confirm';
import defaultLayout from './layouts/DefaultLayout'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import helpers from "./helpers";

import nonDiacriticsSensitiveFilter from './mixins/nonDiacriticsSensitiveFilter';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = process.env.VUE_APP_PROXY || '';

Vue.use(VueAxios, axios)
Vue.use(VuetifyConfirm, { vuetify });
Vue.use(helpers, { store: store, router: router})
Vue.mixin(nonDiacriticsSensitiveFilter);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(defaultLayout)
}).$mount('#app')
